import {GetWsHandlers} from 'store/actions/Index';

const ENV_WEBSOCKET_URL = "REACT_APP_WS_URL"

function transformWebsocketUpdates(message: MessageEvent): (() => void)[] {
    const data = JSON.parse(message.data);

    const handlers = GetWsHandlers(data.type)
    if (handlers.length === 0) {
        return []
    }
    return handlers
        .map((handler: any) => () => handler(data))
}

export class SocketMessage {

    type: string
    data: object

    constructor(type: string, data: object) {
        this.type = type
        this.data = data
    }

    exportJson(): string {
        return JSON.stringify({
            'type': this.type,
            'data': this.data
        })
    }

}

class Socket {

    connected: boolean
    connecting: boolean
    connection: WebSocket | undefined
    getAuth!: () => string
    dispatch: undefined | ((message: () => void) => void)

    url: string | undefined

    constructor() {
        this.connected = false
        this.connecting = false
        this.url = "wss://api.status.galactic-group.nl/ws"
    }

    Connect() {
        if (this.url === undefined) {
            console.error(`websocket url is undefined (${ENV_WEBSOCKET_URL})`)
            return
        }
        if (this.connection !== undefined) {
            // only one init at a time
            return
        }
        this.connection = new WebSocket(this.url)

        this.connection.onclose = (): void => {
            console.info(`WS connection closed`)
            this.connection = undefined
            // setTimeout(this.Connect, 1000);
        }

        this.connection.onerror = (error): void => {
            console.error(`WS connection failed`, error)
            this.Disconnect()
            this.connection = undefined
            // setTimeout(this.Connect, 1000);
        }

        this.connection.onmessage = async (message: MessageEvent<string>) => {
            let transformedMessages = transformWebsocketUpdates(message)
            if (this.dispatch !== undefined) {
                transformedMessages
                    .forEach(transformedMessage => {
                        if (transformedMessage !== undefined) {
                            // @ts-ignore
                            this.dispatch(transformedMessage)
                        }
                    })
            }
        }
    }

    Send(message: any) {
        if (this.connection !== undefined) {
            this.connection.send(JSON.stringify(message))
        }
    }

    Disconnect() {
        if (this.connection === undefined) {
            return
        }
        this.connection.close();
    }


}

const socket = new Socket()

export default socket