import { useParams } from "react-router";


export const GetRouterQuery = <T>(): T => {
    const params = useParams();

    if (params === undefined) {
        //@ts-ignore
        return {}
    }

    //@ts-ignore
    return params;
}