import React from "react";

const Copyright: React.FC = () => {

    return (
        <>
            © {new Date().getFullYear()} Galactic Group - <i>All checks are executed in region nl-rsd-1</i>
        </>
    );
};

export default Copyright;

