import WaitingDots from 'components/WaitingDots';
import React from 'react';
import { RootState } from "../../store/store";
import { connect } from "react-redux";
import { ServicesState } from "../../store/reducers/Services";

interface Props {
    ss: ServicesState;
}

const ServiceStatusBanner: React.FC<Props> = ({ ss }) => {

    if (ss.getServices().length === 0) {
        return <WaitingDots />
    }

    let errors = 0;

    ss.getServices().forEach(service => service.checks.forEach(check => {
        if (check.last_result !== null) {
            if (!check.last_result.ok) {
                errors = errors + 1;
            }
        }
    }))

    return (<>
        {errors > 0 ?
            <div className="alert alert-warning fade show" role="alert" style={{ display: "flex" }}>
                <span className="bx bx-error-alt bx-md" style={{ display: "flex" }} />
                <div style={{ display: "flex", marginLeft: '10px', fontSize: "24px", fontWeight: "lighter" }}>
                    We are experiencing minor service disruptions
                </div>
            </div>
            :
            <>
                <div className="alert alert-success fade show" role="alert" style={{ display: "flex" }}>
                    <span className="bx bx-check-shield bx-md" style={{ display: "flex" }} />
                    <div style={{ display: "flex", marginLeft: '10px', fontSize: "24px", fontWeight: "lighter" }}>
                        All systems operational
                    </div>
                </div>
            </>}
    </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        ss: state.Services,
    };
};

export default connect(mapStateToProps)(ServiceStatusBanner);