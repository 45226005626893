import Copyright from 'components/Copyright';
import PageMeta from 'components/PageMeta';
import React, {useEffect} from 'react';
import {Card, CardBody, CardFooter, Col, Container, Row} from "reactstrap";
import ServiceCard from './Services/ServiceCard';
import ServiceStatusBanner from './Services/ServiceStatusBanner';
import {ReactComponent as LogoDark} from "../assets/images/logo-dark.svg";
import {ServicesState} from 'store/reducers/Services';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {RootState} from 'store/store';
import {getServices} from "../store/actions/Services";
import WaitingDots from "components/WaitingDots";
import {AuthenticationState} from 'store/reducers/Authentication';
import {Service} from "../models/Service";
import logo from "../assets/images/Thousand-Eyes.jpg";
import {OutagesState} from 'store/reducers/Outages';
import {getOutages} from "../store/actions/Outages";
import OutageCard from './Outages/OutageCard';


interface Props {
    servicesState: ServicesState;
    outageState: OutagesState;
    authentication: AuthenticationState;
}


interface Actions {
    getServices: () => void;
    getOutages: () => void;
}

const StatusScreen: React.FC<Props & Actions> = ({
                                                     servicesState,
                                                     getServices,
                                                     outageState,
                                                     getOutages,
                                                     authentication
                                                 }) => {
    useEffect(() => {

        window.document.title = "Status | Galactic Group B.V."

        getServices()
        getOutages()

        // eslint-disable-next-line 
    }, [getServices, getOutages])

    if (servicesState.getServices() === undefined) {
        return <WaitingDots/>
    }


    const regions: { [key: string]: Service[] } = {};
    servicesState.getServices().forEach(service => {
        if (regions[service.region] === undefined) {
            regions[service.region] = [];
        }
        regions[service.region].push(service)
    })

    return (
        <div className="pt-2">
            <Container>
                <PageMeta title={"Status"} tenantName={"Galactic Group"}/>
                <div className="text-center mb-5">
                    <LogoDark/>
                    <br/>
                    <h3 className="mt-4">Status page</h3>
                    <a href={`https://lg.galactic-group.nl`} className="text-primary">Go to looking glass</a>
                </div>
                <ServiceStatusBanner/>
                <Row>

                    <Col xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Card>

                            {
                                Object.keys(regions).sort().map(group => (
                                    <div key={group}>
                                        <h3 className='text-primary mb-4'
                                            style={{margin: "10px 10px 10px 10px"}}>{group}</h3>
                                        {regions[group].map(service => (

                                            <ServiceCard service={service} authentication={authentication}/>

                                        ))}
                                    </div>
                                ))
                            }
                        </Card>
                    </Col>

                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Card>
                            <div>
                                <h3 className='text-primary mb-4'
                                    style={{margin: "10px 10px 10px 10px"}}>Global outages</h3>
                                {
                                    outageState.getOutages().length === 0
                                        ?
                                        <CardBody>No outages</CardBody>
                                        :
                                        <>
                                            {
                                                outageState.getOutages()
                                                    .sort((a, b) => (a.start_time < b.start_time) ? 1 : ((b.start_time <= a.start_time) ? -1 : 0))
                                                    .map((outage) => (

                                                        <OutageCard outage={outage}/>

                                                    ))
                                            }
                                        </>
                                }

                            </div>

                            <CardFooter style={{backgroundColor: "white", textAlign: "center"}}>
                                <small className="text-muted">Insights powered by
                                    &nbsp;
                                    <img
                                        src={logo}
                                        alt=""
                                        style={{width: "90px"}}
                                    />
                                </small>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div className="text-center mb-5">
                <Copyright/>
            </div>
        </div>
    );

}


const mapStateToProps = (state: RootState) => {
    return {
        servicesState: state.Services,
        authentication: state.Authentication,
        outageState: state.Outages
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({getServices, getOutages}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusScreen)