import Copyright from 'components/Copyright';
import PageMeta from 'components/PageMeta';
import React, {useEffect} from 'react';
import {Container} from "reactstrap";
import {ReactComponent as LogoDark} from "../assets/images/logo-dark.svg";
import Traceroute from "../components/Traceroute";
import ServiceStatusBanner from "./Services/ServiceStatusBanner";
import {getServices} from "../store/actions/Services";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

interface Actions {
    getServices: () => void;
}

const LookingGlass: React.FC<Actions> = ({getServices}) => {

    useEffect(() => {

        window.document.title = "Looking Glass | Galactic Group B.V."

        getServices()
    }, [getServices]);

    return (
        <div className="pt-2">
            <Container>
                <PageMeta title={"Looking Glass"} tenantName={"Galactic Group"}/>
                <div className="text-center mb-5">
                    <LogoDark/>
                    <br/>
                    <h3 className="mt-4">Looking Glass</h3>
                    <a href={`https://status.galactic-group.nl`} className="text-primary">Go to status page</a>
                </div>
                <Traceroute/>

            </Container>

            <div style={{bottom: 0, position: "absolute", width: "500px", left: "calc(50% - 250px)"}}
                 className="text-center mb-4 mt-5">

                <div style={{width: "500px", margin: "0 auto"}}>
                    <a href={`https://status.galactic-group.nl`}>
                        <ServiceStatusBanner/>
                    </a>
                </div>
                <Copyright/>
            </div>
        </div>
    );

}


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({getServices}, dispatch);
};
export default connect(undefined, mapDispatchToProps)(LookingGlass)