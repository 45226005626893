import PropTypes from "prop-types";
import React from "react";
import SimpleBar from "simplebar-react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            <li>
              <Link to="/dashboard" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bxs-dashboard"></i>
                <span key="t-dashboard">{props.t("Dashboard")}</span>
              </Link>          
                <Link to="/customers" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="mdi mdi-account-group"></i>
                <span key="t-customers">{props.t("Customers")}</span>
              </Link>          
                <Link to="/invoices" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="fas fa-file-signature"></i>
                <span key="t-invoices">{props.t("Invoices")}</span>
              </Link>
              <Link to="/emails/inbox" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-mail-send"></i>
                <span key="t-emails">{props.t("Emails")}</span>
              </Link>
                 <Link to="/expenses" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bxs-chart"></i>
                <span key="t-expenses">{props.t("Expenses")}</span>
              </Link>
                <Link to="/tenants" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-user-circle"></i>
                <span key="t-tenancies">{props.t("Tenants")}</span>
              </Link>
               <Link to="/users" onClick={() => document.body.classList.toggle("sidebar-enable")}>
                <i className="bx bx-group"></i>
                <span key="t-users">{props.t("Users")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
