import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { AuthenticationState } from "../store/reducers/Authentication";
import { RootState } from "../store/store";

interface Props {
    children: JSX.Element;
    authentication: AuthenticationState;
}

const AuthChecker: React.FC<Props> = ({ children, authentication }) => {
    if (authentication === "") {
        return <Redirect to={`/login`} />
    }
    return children
}

const mapStateToProps = (state: RootState) => {
    return {
        authentication: state.Authentication,
    };
};

export default connect(mapStateToProps)(AuthChecker);
