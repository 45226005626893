import { LOGOUT_USER_ACCOUNT } from "store/actions/Auth";
import { createReducer } from "./Util";
import { GET_OUTAGES } from "store/actions/Outages";
import { Outage } from "models/Outage";

export class OutagesState {

    private outages: { [index: string]: Outage }

    constructor() {
        this.outages = {}
    }

    public setOutages(outages: Outage[]) {
        this.outages = {}
        outages.forEach(outage => {
            this.setOutage(outage)
        })
        return this
    }

    public isFetched(): boolean {
        return Object.values(this.outages).length > 0;
    }

    public setOutage(outage: Outage) {
        this.outages[outage.id] = outage
        return this
    }

    public getOutageById(id: string): Outage | null {
        return this.outages[id] ?? null
    }

    public getOutages(): Outage[] {
        return Object.values(this.outages)
    }

    public deleteOutage(id: string) {
        delete this.outages[id]
        return this
    }

}

const Outages = createReducer<OutagesState>(new OutagesState())
    .handle<Outage[]>([GET_OUTAGES.SUCCESS], (state, payload) => state.setOutages(payload))
    .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new OutagesState());

export default Outages.handler;
