import { Service, ServiceHistory } from 'models/Service';
import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { AuthenticationState } from 'store/reducers/Authentication';
import ServiceSummary from './ServiceSummary';
import { getServiceHistory } from "../../services/Api";
import DashedLine from 'components/DashedLine';

interface Props {
    service: Service;
    authentication: AuthenticationState;
    onClick?: () => void
}

const ServiceDetailCard: React.FC<Props> = ({ service, authentication, onClick }) => {
    const [history, setHistory] = useState<ServiceHistory | null>(null)
    const [data, setData] = useState<{ [check: string]: { name: string, data: number[] } }>({})
    const [lastChecks, setLastChecks] = useState<{ [id: string]: string }>({})

    useEffect(() => {
        setLastChecks({})
        getServiceHistory(service.id).then(newHistory => setHistory(newHistory))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const checks = service.checks;
        checks.forEach(check => {
            if (check.last_result != null) {
                if (lastChecks[check.id] === check.last_result.id || check.last_result.service_id !== service.id || history === null) return;
                Object.keys(check.last_result.result).forEach(metric => {
                    if (history[check.id][metric] !== undefined) {

                        if (check.last_result.ok) {
                            history[check.id][metric].push(check.last_result.result[metric])
                        } else {
                            history[check.id][metric].push(check.last_result.result[metric] * -1)
                        }
                    }
                })

                lastChecks[check.id] = check.last_result.id;
            }
        })
        setHistory(history)
        setLastChecks(lastChecks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service.checks]);

    useEffect(() => {
        if (history === null) return;

        const result: any = [];
        Object.keys(history).forEach((checkId, i) => {
            const check = history[checkId];
            return Object.keys(check).filter(metricName => check[metricName].length > 0).map((metricName) => result.push({
                name: service.checks.find(checkS => checkS.id === checkId)?.name + " " + metricName,
                data: check[metricName]
            }));
        });
        setData(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    return (
        <>
            <CardBody onClick={onClick} style={{ cursor: "pointer" }}>
                <div>
                    <ServiceSummary service={service} authentication={authentication} />
                    <hr className="my-2" />
                    {data &&
                        <DashedLine data={Object.values(data)} />
                    }
                </div>
            </CardBody>
        </>
    );
}

export default ServiceDetailCard;