// @ts-ignore
import _ from "lodash";
 
_.mixin({
  'cloneWithProto': _.partialRight(_.cloneDeep, function (source: any) {
    if (_.isObject(source)) {
      var result = _.cloneDeep(source);
      //@ts-ignore
      result.__proto__ = _.cloneDeep(source.__proto__);
      return result;
    }
  })
});
 
export default function clone<T>(obj: any): T {
  // @ts-ignore
  return _.cloneWithProto(obj)
}
 