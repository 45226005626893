import WaitingDots from 'components/WaitingDots';
import { Service } from 'models/Service';
import Value from "pages/Services/Value";
import React from 'react';
import { connect } from 'react-redux';
import { AuthenticationState } from 'store/reducers/Authentication';
import { RootState } from 'store/store';
import { Col, Row } from "reactstrap";

interface Props {
    service: Service;
    authentication: AuthenticationState;
}

const ServiceSummary: React.FC<Props> = ({ service, authentication }) => {

    if (service === undefined) {
        return <WaitingDots />
    }


    let errors = 0;

    service.checks.forEach(check => {
        if (check.last_result !== null) {
            if (!check.last_result.ok) {
                errors = errors + 1;
            }
        }
    })


    return (<>
        <Row>
            <Col md={5} xs={12}>
                <div className="text" style={{ display: "flex" }}>
                    {
                        errors > 0
                            ?
                            <span className="bx bx-error-alt bx-md"
                                style={{ display: "flex", color: "orange", marginRight: "10px" }} />
                            :
                            <span className="bx bx-check-shield bx-md"
                                style={{ display: "flex", color: "green", marginRight: "10px" }} />
                    }
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h5 className="text-primary mb-0 mt-2">{service.name}</h5>
                    </div>
                </div>
            </Col>
            <Col md={5 - (service.checks.length * 2)}
                xs={8 - (service.checks.length * 3)}
                sm={8 - (service.checks.length * 3)} />
            {service.checks.map(check => {
                if (check.last_result != null) {
                    const time = check.last_result.result.rtt ?? check.last_result.result.time
                    return <Col key={check.id} md={3} sm={4} xs={4} className={"float-right"}>
                        <div className="text">
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                <h5 className="mb-0" style={{
                                    textAlign: "center",
                                    color: check.last_result.ok ? "green" : "orange"
                                }}>{check.name}</h5>
                                <Value value={(time < 0 || time > 2999) ? "∞" : time ?? "∞"} unit={'ms'} />
                            </div>
                        </div>
                    </Col>
                }
                return <></>
            })}
        </Row>
    </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        authentication: state.Authentication
    };
};

export default connect(mapStateToProps, null)(ServiceSummary);
