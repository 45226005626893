import { Network } from "./Network";
import { Location } from "./Location";


export class Outage {


    constructor() {
        this.locations = [];
    }


    id!: string;
    network!: Network;
    locations!: Location[];
    start_time!: Date;
    duration!: number;
}
