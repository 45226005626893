import React, { useState, useEffect } from "react";

const WaitingDots: React.FC = () => {
    const rollOver = 4;
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (amount + 1 === rollOver) {
                setAmount(0)
            } else {
                setAmount(amount + 1)
            }
        }, 1000);
        return () => clearInterval(interval);
      });

    let response = ""
    for (let i = 0; i < amount; i++) {
        response += "."
    }
    
    return <span>{response}</span>
}

export default WaitingDots;