import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";

interface Props {
  title: string;
  tenantName: string;
}

const PageMeta: React.FC<Props> = ({ title, tenantName }: Props) => {
  return (
      <title> {title} {" | " + tenantName}</title>
  );
};

const mapStateToProps = (state: RootState) => {
  return {

  };
};

export default connect(mapStateToProps)(PageMeta);