import { RequestTypes, generateAction, EventType } from "./Util";
import * as api from "../../services/Api";
import { RegisterWsHandler } from "./Index";

export const GET_SERVICES = new RequestTypes("GET_SERVICES");
export const GET_SERVICE = new RequestTypes("GET_SERVICE");
export const SERVICE_LAST_CHECK_UPDATED = new EventType("SERVICE_LAST_CHECK_UPDATED");

export const getServices = () =>
    generateAction(GET_SERVICES, api.getServices);

export const getService = (id: string) => {
    return generateAction(GET_SERVICE, () =>
        api.getService(id)
    );
};

export const serviceLastCheckUpdated = (payload: any) =>
    (dispatch: any) =>
        dispatch(SERVICE_LAST_CHECK_UPDATED.payload(payload))
RegisterWsHandler("last_check_update", serviceLastCheckUpdated)
