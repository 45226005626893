import { GetRouterQuery } from 'components/Utils/GetRouterQuery';
import WaitingDots from 'components/WaitingDots';
import { Service } from 'models/Service';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { AuthenticationState } from 'store/reducers/Authentication';
import ServiceDetailCard from './ServiceDetailCard';
import ServiceSummary from './ServiceSummary';

interface Props {
    service: Service;
    authentication: AuthenticationState;
}

const ServiceCard: React.FC<Props> = ({ service, authentication }) => {

    const history = useHistory();

    const { id } = GetRouterQuery<{ id: string }>()

    const goToDetail = (id: string) => {
        history.push("/service/" + id);
    }

    if (service === undefined) {
        return <WaitingDots />
    }

    return (<>
        <Card style={{ margin: "10px 10px 10px 10px" }}>
            {id === service.id ?
                <ServiceDetailCard onClick={() => history.push("/")} service={service}
                    authentication={authentication} />
                :
                <CardBody style={{ cursor: "pointer" }} onClick={() => goToDetail(service.id)}>
                    <ServiceSummary service={service} authentication={authentication} />
                </CardBody>
            }
        </Card>

    </>
    );
}

export default ServiceCard;