import WaitingDots from 'components/WaitingDots';
import { Outage } from 'models/Outage';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';


interface Props {
    outage: Outage;
}

const OutageCard: React.FC<Props> = ({ outage }) => {

    function fromNow(date: Date, nowDate = Date.now(), rft = new Intl.RelativeTimeFormat('en', { numeric: "auto" })) {
        const SECOND = 1000;
        const MINUTE = 60 * SECOND;
        const HOUR = 60 * MINUTE;
        const DAY = 24 * HOUR;
        const WEEK = 7 * DAY;
        const MONTH = 30 * DAY;
        const YEAR = 365 * DAY;
        const intervals = [
            { ge: YEAR, divisor: YEAR, unit: 'year' },
            { ge: MONTH, divisor: MONTH, unit: 'month' },
            { ge: WEEK, divisor: WEEK, unit: 'week' },
            { ge: DAY, divisor: DAY, unit: 'day' },
            { ge: HOUR, divisor: HOUR, unit: 'hour' },
            { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
            { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
            { ge: 0, divisor: 1, text: 'just now' },
        ];
        //@ts-ignore
        const now = typeof nowDate === 'object' ? nowDate.getTime() : new Date(nowDate).getTime();
        const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
        const diffAbs = Math.abs(diff);
        for (const interval of intervals) {
            if (diffAbs >= interval.ge) {
                const x = Math.round(Math.abs(diff) / interval.divisor);
                const isFuture = diff < 0;
                //@ts-ignore
                return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text;
            }
        }
    }

    if (outage === undefined) {
        return <WaitingDots />
    }

    return (<>
        <Card style={{ margin: "10px 10px 10px 10px" }}>
            <CardBody>
                <div>
                    <Row>
                        <Col md={12} xs={12}>
                            <h6 className="text mb-0">{outage.network.name}</h6>
                            {outage.locations.map((location) => {
                                return <>
                                    <span className="badge bg-primary">{location.name}</span><br />
                                </>
                            })}
                            <br />
                            <small className="text-muted" style={{ float: "right" }}>{fromNow(outage.start_time)}</small>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>

    </>
    );
}

export default OutageCard;