import React from "react";
import {runTraceroute} from "../services/Api";
import {countries} from "../common/Countries";
import {Spinner} from "reactstrap";

interface Props {

}

const Traceroute: React.FC<Props> = () => {
    const [target, setTarget] = React.useState<string>("")
    const [result, setResult] = React.useState<any>(undefined)
    const [running, setRunning] = React.useState<boolean>(false)
    const [error, setError] = React.useState<string | null>(null)
    const run = async () => {
        try {
            setError(null)
            setResult({})
            setRunning(true)
            await runTraceroute(target, res => {
                console.log(Object.values(res)[0])
                setResult((result: any) => {
                    return {
                        ...result,
                        // @ts-ignore
                        [Object.values(res)[0].distance]: Object.values(res)[0]
                    }

                })
            });

            setRunning(false)
        } catch (e) {
            setResult(undefined)
            setRunning(false)
            setError("Traceroute failed")
        }
    }
    return <div style={{textAlign: "center", marginBottom: "20px;"}}>
        <div style={{textAlign: "center", marginBottom: "5px"}}>
            <input
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        run()
                    }
                }}
                placeholder={"Traceroute target"} style={{
                border: "1px solid grey",
                borderRadius: "5px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                padding: "10px",
                width: "500x",
                height: "40px",
                fontSize: "20x"
            }} type={"text"} value={target} onChange={(e) => setTarget(e.target.value)}/>
            <button style={{
                border: "1px solid grey",
                borderRadius: "5px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                padding: "2px",
                width: "100px",
                background: "white",
                height: "40px",
                fontSize: "20x"
            }} onClick={run}>Run
            </button>
        </div>
        <br />

        {error &&
            <span style={{color: "red"}}>{error}</span>
        }


        {result &&
            <div style={{margin: "0 auto", textAlign: "left", width: "500px"}}>
                {Object.values(result).map((hop: any) => {
                    return <div style={{
                        padding: "10px",
                        height: "60px",
                        borderBottom: "1px solid #ccc"
                    }}>
                        <div style={{float: "left", width: "50px"}}>
                            <b>{hop.distance}</b>
                        </div>
                        <div style={{float: "left", width: "300px"}}>
                            <div><span
                                title={countries.find(country => country.code === hop.country)?.name}>{countries.find(country => country.code === hop.country)?.emoji}</span> [AS{hop.asn}] {hop.ip}<br/>
                                <i style={{wordBreak: "keep-all"}}>{hop.hostname}</i>
                            </div>

                        </div>
                        <div style={{float: "right"}}>
                            <div>{(hop.rtt / 1000 / 1000).toFixed(2)} ms</div>
                        </div>
                    </div>

                })}
            </div>
        }

        {running &&
            <>
                <br/>
                <Spinner/>
            </>
        }
    </div>
}

export default Traceroute