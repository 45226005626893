import { Service } from "models/Service";
import { LOGOUT_USER_ACCOUNT } from "store/actions/Auth";
import { createReducer } from "./Util";
import { GET_SERVICES, GET_SERVICE, SERVICE_LAST_CHECK_UPDATED } from "store/actions/Services";

export class ServicesState {

    private services: { [index: string]: Service }

    constructor() {
        this.services = {}
    }

    public setServices(services: Service[]) {
        this.services = {}
        services.forEach(service => {
            this.setService(service)
        })
        return this
    }

    public isFetched(): boolean {
        return Object.values(this.services).length > 0;
    }

    public setService(service: Service) {
        this.services[service.id] = service
        return this
    }

    public getServiceById(id: string): Service | null {
        return this.services[id] ?? null
    }

    public getServices(): Service[] {
        return Object.values(this.services)
    }

    public deleteService(id: string) {
        delete this.services[id]
        return this
    }

}

const Services = createReducer<ServicesState>(new ServicesState())
    .handle<Service[]>([GET_SERVICES.SUCCESS], (state, payload) => state.setServices(payload))
    .handle<any>([SERVICE_LAST_CHECK_UPDATED.EVENT], (state, payload) => {
        const service = state.getServiceById(payload.service_id);
        if (service === null) {
            return state
        }
        service.checks = service.checks.map(check => {
            if (check.id !== payload.check_id) {
                return check;
            }
            check.last_result = payload
            return check
        })

        state.setService(service)
        return state;
    })
    .handle<Service>([GET_SERVICE.SUCCESS], (state, payload) => state.setService(payload))
    .handle([LOGOUT_USER_ACCOUNT.SUCCESS], () => new ServicesState());

export default Services.handler;
