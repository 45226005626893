import axios from 'axios';
import { plainToClass } from 'class-transformer';
import { Outage } from 'models/Outage';
import { Service, ServiceHistory } from 'models/Service';
import { User } from 'models/User';
import "reflect-metadata";

const api = axios.create();

//TODO: CHANGE API URL TO STATUS -> INCL. AUTH
const baseUrl = `https://api.status.galactic-group.nl/v1`;
const baseUrlv2 = `https://api.status.galactic-group.nl/v2`;

export function loginUserAccount(email: string, password: string, mfa_token: string): Promise<string> {
    return api.post(`${baseUrl}/auth`, { email, password, mfa_token })
        .then(response => response.data.token);
}

export function getUser(): Promise<User> {
    return api.get(`${baseUrl}/user`)
        .then(response => plainToClass(User, response.data));
}


export function runTraceroute(target: string, cb: (data: any) => void): Promise<any> {
    return new Promise((resolve, reject) => {

        const ws  = new WebSocket(`${baseUrlv2}/traceroute/${target}`)
        ws.onmessage = (event) => {
            cb(JSON.parse(event.data))
        }
        ws.onclose = () => {
            resolve()
        }

    });
}

export function getServices(): Promise<Service[]> {
    return api.get(`${baseUrl}/services`)
        .then(response => response.data.map((service: object): Service => plainToClass(Service, service)));
}

export function addService(service: Service): Promise<Service> {
    return api.post(`${baseUrl}/services`, service)
        .then(response => plainToClass(Service, response.data));
}

export function editService(service: Service): Promise<Service> {
    return api.put(`${baseUrl}/services/` + service.id, service)
        .then(response => plainToClass(Service, response.data));
}

export function removeService(service: Service): Promise<Service> {
    return api.delete(`${baseUrl}/services/` + service.id)
        .then(response => plainToClass(Service, response.data));
}

export function getService(id: string): Promise<Service> {
    return api.get(`${baseUrl}/services/` + id)
        .then(response => plainToClass(Service, response.data));
}

export function getServiceHistory(id: string): Promise<ServiceHistory> {
    return api.get(`${baseUrl}/services/${id}/history`)
        .then(response => response.data);
}

export function getOutages(): Promise<Outage[]> {
    return api.get(`${baseUrl}/outages`)
        .then(response => response.data.map((outage: object): Outage => plainToClass(Outage, outage)));
}

export default api;