import React, { useEffect, useState } from 'react';

interface Props {
    value: number;
    unit: string;
}

const Value = ({value, unit}: Props) => {
    const [highlighted, setHighlighted] = useState(false);
    const [curVal, setCurVal] = useState(value);

    useEffect(() => {
        setHighlighted(true);
        const int = setInterval(() => {
            setCurVal(curVal => {
                if (curVal === value) {
                    clearInterval(int);
                    return curVal;
                }
                if (curVal > value) {
                    return curVal - 1;
                }
                if (curVal < value) {
                    return curVal + 1;
                }
                return curVal
            });
        }, 1000 / Math.abs(curVal-value));

        setTimeout(() => {
            setHighlighted(false);
        }, 1500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const main = {
        transition: "0.25s ease-in-out",
        padding:"2px 4px"
    };

    return <span><span style={
        highlighted ? {
            background: "#f8ffb4",
            ...main
        } : main}>{curVal} { unit }</span></span>;
}

export default Value;