import clone from "common/clone";
import { Action } from "../actions/Util";

type ReducerCallback<T, R> = (state: T, action: R) => T
type ReducerHandlers = { [index: string]: ReducerCallback<any, any> }

class Reducer<T> {

    constructor(initialState: T) {
        this.initialState = initialState;
        this.handlers = {};
    }

    initialState: T;
    state!: T;
    handlers: ReducerHandlers;

    public handle<R>(types: string | string[], callback: ReducerCallback<T, R>): Reducer<T> {
        if (typeof types === "string") {
            types = [types]
        }
        types.map(type => {
            return this.handlers[type] = callback
        })
        return this
    }

    public handler = (state: T, action: Action): T => {
        if (state === undefined) {
            state = this.initialState;
        }

        let newState = clone<T>(state)

        if (this.handlers[action.type] !== undefined) {
            newState = this.handlers[action.type](newState, action.payload)
        }

        if (JSON.stringify(newState) !== JSON.stringify(state)) {
            return newState
        }

        return state
    }
}

export function createReducer<T>(initialState: T) {
    return new Reducer<T>(initialState)
}
