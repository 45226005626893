import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout";
import StatusScreen from "pages/StatusScreen";
import AuthProtected from "./components/AuthProtected";
import LookingGlass from "./pages/LookingGlass";


const routes = [
    {path: "/", auth: false, component: StatusScreen, layout: VerticalLayout},
    {path: "/service/:id", auth: false, component: StatusScreen, layout: VerticalLayout},
]

const App: React.FC = () => {

    if (window.location.hostname === "lg.galactic-group.nl") {
        return <LookingGlass/>
    }
    return (
        <Router>
            <Switch>
                {routes.map((route: any, index: number) => {
                    return <Route
                        key={index}
                        exact
                        path={route.path}
                        render={
                            () => route.auth ?
                                <AuthProtected>
                                    <route.component/>
                                </AuthProtected>
                                :
                                <NonAuthLayout>
                                    <route.component/>
                                </NonAuthLayout>
                        }
                    />
                })}
            </Switch>
            <Redirect to={`/`}/>
        </Router>
    );
};


export default App;
