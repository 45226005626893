import { combineReducers } from 'redux';
import Authentication from './Authentication';
import Services from './Services';
import Outages from './Outages';


export default combineReducers({
   Services,
   Authentication,
   Outages
});
